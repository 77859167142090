import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AsadaContext from "../../context/asada/asadaContext";

function ImagenesAsada() {
  const { isLoading, getAsadaImagenes, createAsadaImagen, imagenes } =
    useContext(AsadaContext);
  const [isTanqueBtnDisabled, setIsTanqueBtnDisabled] = useState(true);
  const [isGradienteBtnDisabled, setIsGradienteBtnDisabled] = useState(true);
  const [isServiciosBtnDisabled, setIsServiciosBtnDisabled] = useState(true);

  const [tankImg, setTankImg] = useState("");
  const [gradienteImg, setGradienteImg] = useState("");
  const [serviciosImg, setServiciosImg] = useState("");

  const [tankImgFilename, setTankImgFilename] = useState("");
  const [gradienteImgFilename, setGradienteImgFilename] = useState("");
  const [serviciosImgFilename, setServiciosImgFilename] = useState("");

  const navigate = useNavigate();

  const handleImgTanqueSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.set("type", "tanque");
    fd.append("image", tankImg[0]);
    await createAsadaImagen(fd);
  };
  const handleImgGradienteSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.set("type", "gradiente");
    fd.append("image", gradienteImg[0]);
    await createAsadaImagen(fd);
  };

  const handleImgServiciosSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.set("type", "servicios");
    fd.append("image", serviciosImg[0]);
    await createAsadaImagen(fd);
  };

  useEffect(() => {
    const fetchImagenes = async () => {
      await getAsadaImagenes();
    };
    if (imagenes.length <= 0) fetchImagenes();
  }, []);

  useEffect(() => {
    if (imagenes.length > 0) {
      setTankImgFilename(
        imagenes.filter((item) => item.isTanqueImg === 1)[0].filename
      );
      setGradienteImgFilename(
        imagenes.filter((item) => item.isGradienteImg === 1)[0].filename
      );
      setServiciosImgFilename(
        imagenes.filter((item) => item.isServiciosImg === 1)[0].filename
      );
    }
  }, [imagenes]);

  return (
    <>
      <div className='text-lg'>
        <form className='w-full max-w-lg' onSubmit={handleImgTanqueSubmit}>
          <div className='px-3 '>
            <label
              className='block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2 '
              htmlFor='grid-tanque'
            >
              Página de Inicio | Foto de tanque de almacenamiento
            </label>
            <div className='p-2 mb-3 h-[280px]'>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/uploads/${tankImgFilename}`}
                // src={`http://localhost:5050/uploads/${tankImgFilename}`}
                alt='Foto de tanque'
                className='object-cover h-full w-full'
              />
            </div>
            <div className='form-control'>
              <input
                className='appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-tanque'
                type='file'
                onChange={(e) => {
                  setIsTanqueBtnDisabled(false);
                  setTankImg(e.target.files);
                }}
              />
            </div>
            <button
              className={`btn btn-primary btn-md ${isLoading ? "loading" : ""}`}
              type='submit'
              disabled={isTanqueBtnDisabled}
            >
              {isLoading ? (
                <></>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                </svg>
              )}
            </button>
          </div>
        </form>
      </div>
      <div className='text-lg mt-5'>
        <form className='w-full max-w-lg' onSubmit={handleImgGradienteSubmit}>
          <div className='px-3 '>
            <label
              className='block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2 '
              htmlFor='grid-gradiente'
            >
              Página de Inicio | Foto de quiebra gradientes
            </label>
            <div className='p-2 mb-3 h-[280px]'>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/uploads/${gradienteImgFilename}`}
                // src={`http://localhost:5050/uploads/${tankImgFilename}`}
                alt='Foto de gradiente'
                className='object-cover h-full w-full'
              />
            </div>
            <div className='form-control'>
              <input
                className='appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-gradiente'
                type='file'
                onChange={(e) => {
                  setIsGradienteBtnDisabled(false);
                  setGradienteImg(e.target.files);
                }}
              />
            </div>
            <button
              className={`btn btn-primary btn-md ${isLoading ? "loading" : ""}`}
              type='submit'
              disabled={isGradienteBtnDisabled}
            >
              {isLoading ? (
                <></>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                </svg>
              )}
            </button>
          </div>
        </form>
      </div>
      <div className='text-lg mt-5'>
        <form className='w-full max-w-lg' onSubmit={handleImgServiciosSubmit}>
          <div className='px-3 '>
            <label
              className='block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2 '
              htmlFor='grid-servicios'
            >
              Página de Inicio | Foto de sección de servicios
            </label>
            <div className='p-2 mb-3 h-[280px]'>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/uploads/${serviciosImgFilename}`}
                // src={`http://localhost:5050/uploads/${tankImgFilename}`}
                alt='Foto de sección de servicios'
                className='object-cover h-full w-full'
              />
            </div>
            <div className='form-control'>
              <input
                className='appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                id='grid-servicios'
                type='file'
                onChange={(e) => {
                  setIsServiciosBtnDisabled(false);
                  setServiciosImg(e.target.files);
                }}
              />
            </div>
            <button
              className={`btn btn-primary btn-md ${isLoading ? "loading" : ""}`}
              type='submit'
              disabled={isServiciosBtnDisabled}
            >
              {isLoading ? (
                <></>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path d='M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z' />
                </svg>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ImagenesAsada;
