import { Parallax } from "react-parallax";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../components/hooks/useWindowDimensions";
import image1 from "../../assets/projectmain.jpg";

function HomeProjects({ asada }) {
  const { width } = useWindowDimensions();
  const { texto_proyectos } = asada;

  if (width > 768) {
    return (
      <section className='home-projects flex'>
        <div className='projects-info flex flex-col justify-start'>
          <h2 className='text-7xl text-white px-5 '>Nuestros Proyectos</h2>
          <p className='text-lg px-5 py-6 text-white'>
            {asada && texto_proyectos}
          </p>
          <NavLink to='proyectos' className='btn btn-secondary mt-5 btn-lg'>
            Ver proyectos{" "}
          </NavLink>
        </div>

        <div className='projects-image'>
          <Parallax bgImage={image1} strength={400}>
            <div></div>
          </Parallax>
        </div>
      </section>
    );
  } else {
    return (
      <section className='home-projects flex'>
        <div className='projects-info flex flex-col justify-start'>
          <h2 className='lg:text-7xl md:text-4xl text-3xl text-white px-5'>
            Nuestros Proyectos
          </h2>
          <p className='text-lg px-5 py-6 text-white text-justify'>
            {texto_proyectos}
          </p>
          <NavLink to='projects' className='btn btn-secondary mt-5 btn-lg'>
            Ver proyectos{" "}
          </NavLink>
        </div>
      </section>
    );
  }
}

export default HomeProjects;
